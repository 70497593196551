export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'

    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU_TRIGGER = '[data-trigger="burger-submenu-toggle"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static BURGER_SUBMENU_WRAPPER = '[data-element="burger-submenus-wrapper"]'

    static LANG_SWICTH_TRIGGER = "[data-trigger='lang-switcher']"
    static LANG_SWICTH_ELEMENT = "[data-element='lang-switcher-list']"
}
